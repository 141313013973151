.review_section{
     width: 100%;
     height: 300px;
     background-color: red;
}
.review-border{
    border-top: 1px solid black; 
    opacity: 0.25;
    width: 50%;
    margin: 0 auto;
}