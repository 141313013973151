.middle_page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.center_nav{
  position: absolute;
  left: 50%;
  transform: translate(-50%,0%);
}
nav{
  background-color: #040D12;
}
.with-border {
  border-right: 1px solid #ccc; /* Adjust the color as needed */
}
.nav-hover-effect:hover {
  background-color: #ebeced; /* Change this to the desired hover color */
}
.profile-pics{
  height: 100px;
  border-radius: 15%;
}
.champion-icon{
  height: 50px;
}
.item-icon{
  height: 25px;
}
.image-container {
  width: 150px; /* Set width of the container */
  height: 150px; /* Set height of the container */
  overflow: hidden; /* Hide overflow */
}
.image-container img {
  height: 200%; /* Ensure image takes full height of container */
  object-fit: cover; /* Cover the container while maintaining aspect ratio */
}